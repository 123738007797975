import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import Social from "../components/social"

const About = ({ data, location }) => {
    let global = data.contentfulWebsite;
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-container o-content o-content-container o-content-container--large u-text-center">
                <h1>About Us</h1>
                <div className="c-video">
                    <iframe title="carousel-video" className="c-video__frame u-img-border" src={`https://www.youtube.com/embed/${data.contentfulAboutPage.videoUrl.substring(data.contentfulAboutPage.videoUrl.lastIndexOf('/') + 1)}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div>
                    <hr />
                    <div className="h2 u-text-normalcase">{data.contentfulAboutPage.primaryContent.primaryContent}</div>
                    <hr />
                    <div className="o-flex o-flex--third o-flex--align-stretch">
                        {data.contentfulAboutPage.primaryCards.map((column, index) =>
                            <div key={index}>
                                <img alt={column.title} src={column.image.resize.src} />
                                <div className="h3">{column.title}</div>
                                <div>{column.subtitle}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <hr />
                    <div className="h2 u-text-normalcase">{data.contentfulAboutPage.secondaryContent.secondaryContent}</div>
                    <hr />
                    <div className="o-flex o-flex--third o-flex--align-stretch">
                        {data.contentfulAboutPage.secondaryCards.map((column, index) =>
                            <div key={index}>
                                <img alt={column.title} src={column.image.resize.src} />
                                <div className="h3">{column.title}</div>
                                <div>{column.subtitle}</div>
                            </div>
                        )}
                    </div>
                </div>
                <Social type="body"></Social>
            </div>
        </Layout>
    );
};

export default About

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        shareImage {
            file {
                url
            }
        }
    }
    contentfulAboutPage(website: {title: {eq: "UNINTERRUPTED Canada"}}) {
        primaryContent {
            primaryContent
        }
        secondaryContent {
            secondaryContent
        }
        primaryCards {
            title
            image {
                resize(width: 1080) {
                    src
                }
            }
            subtitle
        }
        secondaryCards {
            title
            image {
                resize(width: 1080) {
                    src
                }
            }
            subtitle
        }
        videoUrl
    }
}
`;
